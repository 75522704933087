import { accordionAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle } = createMultiStyleConfigHelpers(accordionAnatomy.keys);

export const linkVariant = definePartsStyle({
    container: {
        border: 'none',
    },
    button: {
        p: 0,
        display: 'inline',
        color: 'general.blue',
        fontWeight: 'bold',
        w: 'auto',
        _hover: {
            bg: 'transparent',
            textDecor: 'underline',
        },
    },
    panel: {
        p: 0,
    },
});
