import { defineStyleConfig } from '@chakra-ui/react';

import { sizes, variantFast } from '../Input';

export const Select = defineStyleConfig({
    sizes: {
        sm: {
            field: {
                ...sizes.sm,
            },
        },
        md: {
            field: {
                ...sizes.md,
            },
        },
        lg: {
            field: {
                ...sizes.lg,
            },
        },
    },
    variants: {
        fast: variantFast,
    },
    defaultProps: {
        size: 'md',
        variant: 'fast',
    },
});
