import { useMemo } from 'react';
import Cookies from 'js-cookie';

export type CsrfToken = {
    csrfmiddlewaretoken: string;
};

/**
 * Get CSRF token.
 *
 * @returns object with csrf token
 */
export function getCsrfToken(): CsrfToken {
    const token = Cookies.get('csrftoken');

    return {
        csrfmiddlewaretoken: token ?? '',
    };
}

/**
 * Hook CSRF token.
 *
 * @returns object with csrf token
 */
export function useCsrfToken() {
    return useMemo<CsrfToken>(getCsrfToken, []);
}
