import { accordionAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle } = createMultiStyleConfigHelpers(accordionAnatomy.keys);

export const simpleVariant = definePartsStyle({
    container: {
        border: 'none',
    },
    button: {
        w: 'auto',
        _hover: {
            bg: 'transparent',
        },
    },

    icon: {
        ml: 2.5,
    },
});
