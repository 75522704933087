import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle } = createMultiStyleConfigHelpers(inputAnatomy.keys);

export const variantFast = definePartsStyle(() => {
    return {
        field: {
            boxShadow:
                'inset -10px -10px 10px rgba(255, 255, 255, 0.3), inset 10px 10px 10px rgba(103, 114, 229, 0.04)',
            borderRadius: '1rem',
            borderWidth: '1px',
            borderColor: 'general.lightenGray',
            color: 'general.font',

            bg: '#fff',
            _hover: {
                bg: '#fff',
                borderColor: 'general.lightenGray',
            },
            _focus: {
                borderColor: 'general.blue',
            },
            _invalid: {
                borderColor: 'red.500',
            },
            _readOnly: {
                borderColor: 'general.lightenGray',
            },
        },
    };
});
