import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
    tabsAnatomy.keys,
);

const lineVariant = definePartsStyle((props) => {
    const { colorScheme: c } = props;

    return {
        tablist: {
            borderBottom: 'none',
        },
        tab: {
            color: 'general.darkBlue',
            _selected: {
                color: `${c}.500`,
                fontWeight: 'bold',
                borderColor: `${c}.500 !important`,
            },
            _hover: {
                borderColor: 'rgba(73, 85, 255, 0.2)',
            },
            _active: {
                bg: 'transparent',
            },
        },
    };
});

const sizeSm = definePartsStyle({
    tab: {
        fontSize: 'xs',
    },
});

const sizeMd = definePartsStyle({
    tab: {
        fontSize: 'sm',
    },
});

const sizeLg = definePartsStyle({
    tab: {
        fontSize: 'md',
    },
});

export const Tabs = defineMultiStyleConfig({
    variants: {
        line: lineVariant,
    },
    sizes: {
        sm: sizeSm,
        md: sizeMd,
        lg: sizeLg,
    },
    defaultProps: {
        variant: 'line',
        size: 'md',
        colorScheme: 'primary',
    },
});
