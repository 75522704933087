import { popoverAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, cssVar } from '@chakra-ui/react';
const $arrowBg = cssVar('popper-arrow-bg');

const { definePartsStyle } = createMultiStyleConfigHelpers(popoverAnatomy.keys);

export const hintVariant = definePartsStyle({
    header: {
        fontWeight: 'bold',
        fontSize: 'lg',
    },

    content: {
        borderRadius: 'sm',
        fontSize: 'sm',
        bg: 'general.darkBlue',
        color: 'white',
        [$arrowBg.variable]: 'colors.general.darkBlue',
        p: 2,
    },
});
