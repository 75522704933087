import { defineStyleConfig } from '@chakra-ui/react';

export const Link = defineStyleConfig({
    baseStyle: {
        textDecor: 'none',
    },
    variants: {
        link: {
            color: 'general.blue',
            fontWeight: 'bold',
        },
        legacy: {
            color: 'primary.500',
            fontWeight: 'bold',
            textDecoration: 'none',
            _hover: {
                color: 'general.linkHover',
                textDecoration: 'none',
            },
            _focus: {
                color: 'general.linkHover',
            },
            _active: {
                textDecoration: 'underline',
            },
        },
    },
    defaultProps: {
        variant: 'link',
    },
});
