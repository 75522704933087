import { switchAnatomy } from '@chakra-ui/anatomy';
import { cssVar, createMultiStyleConfigHelpers, StyleFunctionProps } from '@chakra-ui/react';

const { definePartsStyle } = createMultiStyleConfigHelpers(switchAnatomy.keys);

export const outsideVariant = definePartsStyle((props) => {
    const { colorScheme: c } = props;

    return {
        container: {
            display: 'flex',
            alignItems: 'center',
            gap: 2,
        },

        thumb: {
            bg: `general.lightenGray`,
            _checked: {
                bg: `${c}.500`,
            },
        },
        track: {
            alignItems: 'center',
            bg: 'general.invisibleGray',
            _checked: {
                bg: 'general.invisibleGray',
            },
            p: 0,
        },
    };
});

export const outsideSizer = ({
    props,
    thumbSize,
}: {
    props: StyleFunctionProps;
    thumbSize: number;
}) => {
    const { theme } = props;
    const $switchThumbX = cssVar('switch-thumb-x');
    const trackWidth = Math.round(thumbSize * 1.67);
    const trackHeight = Math.floor(trackWidth / 2);

    return {
        container: {
            [$switchThumbX.variable]: theme.space[trackWidth - thumbSize],
        },
        thumb: {
            h: thumbSize,
            w: thumbSize,
        },
        track: {
            w: trackWidth,
            h: trackHeight,
        },
    };
};
