import { popoverAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, cssVar } from '@chakra-ui/react';
const $arrowBg = cssVar('popper-arrow-bg');

const { definePartsStyle } = createMultiStyleConfigHelpers(popoverAnatomy.keys);

export const dialogVariant = definePartsStyle({
    header: {
        p: 0,
        mb: 4,
        fontWeight: 'bold',
        fontSize: 'lg',
        color: 'general.heading',
        lineHeight: '150%',
        letterSpacing: '-0.36px',
    },

    body: {
        p: 0,
    },

    content: {
        [$arrowBg.variable]: 'white',
        bg: 'white',
        borderRadius: 'xl',
        fontSize: 'sm',
        color: 'general.fontGray',
        p: '3rem',
        lineHeight: '140%',
        letterSpacing: '0.26px',
        boxShadow: '0px 30px 60px rgba(65, 55, 181, 0.13)',
    },
});
