import { ChakraProvider } from '@chakra-ui/react';
import { useRef, type ComponentProps } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { theme } from '@/modules/design/theme';
import { GlobalContextProvider } from '@/modules/core/contexts/GlobalContext';
import { AppContextProvider } from '@/modules/core/contexts/AppContext';
import '@/setup/i18n';

const queryClient = new QueryClient();

export const App = (props: ComponentProps<any>) => {
    const containerRef = useRef<HTMLDivElement>(null);

    return (
        <GlobalContextProvider>
            <div ref={containerRef} />
            <AppContextProvider containerRef={containerRef}>
                <QueryClientProvider client={queryClient}>
                    <ChakraProvider theme={theme} toastOptions={{ portalProps: { containerRef } }}>
                        {props.children}
                    </ChakraProvider>
                </QueryClientProvider>
            </AppContextProvider>
        </GlobalContextProvider>
    );
};
