import { defineStyleConfig } from '@chakra-ui/react';

import { variantFast, sizes } from '../Input';

export const Textarea = defineStyleConfig({
    sizes: {
        sm: {
            ...sizes.sm,
            h: 'auto',
            minH: '8rem',
        },
        md: {
            ...sizes.md,
            h: 'auto',
            minH: '12rem',
        },
        lg: {
            ...sizes.lg,
            h: 'auto',
            minH: '14rem',
        },
    },
    variants: {
        fast: {
            ...variantFast().field,
        },
    },
    defaultProps: {
        size: 'md',
        variant: 'fast',
    },
});
