import { accordionAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { baseVariant, simpleVariant, linkVariant } from './variants';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(accordionAnatomy.keys);

export const Accordion = defineMultiStyleConfig({
    variants: {
        base: baseVariant,
        simple: simpleVariant,
        link: linkVariant,
    },
    defaultProps: {
        variant: 'base',
    },
});
