import { defineStyleConfig } from '@chakra-ui/react';

import { textStyles } from '../../textStyles';

export const Heading = defineStyleConfig({
    baseStyle: {
        letterSpacing: '-0.02rem',
    },
    sizes: {
        '4xl': {
            fontSize: '7xl',
        },
        '3xl': {
            fontSize: '6xl',
        },
        '2xl': {
            fontSize: '5xl',
        },
        xl: {
            fontSize: '4xl',
        },
        lg: {
            fontSize: '3xl',
        },
        md: {
            fontSize: '2xl',
        },
        sm: {
            fontSize: 'xl',
        },
        xs: {
            fontSize: 'lg',
        },
        h1: textStyles.h1,
        h2: textStyles.h2,
        h3: textStyles.h3,
        h4: textStyles.h4,
        h5: textStyles.h5,
    },
});
