import { avatarAnatomy } from '@chakra-ui/anatomy';
import {
    createMultiStyleConfigHelpers,
    defineStyle,
    Avatar as AvatarComponent,
} from '@chakra-ui/react';

AvatarComponent.defaultProps = {
    borderRadius: 'inherit',
};

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
    avatarAnatomy.keys,
);

const baseStyle = definePartsStyle({
    badge: {},
    container: { borderRadius: '100%' },
    excessLabel: { borderRadius: '100%' },
    group: {},
});

const sizeSm = defineStyle({
    w: 9,
    h: 9,
});

const sizeMd = defineStyle({
    w: 14,
    h: 14,
});

const sizeLg = defineStyle({
    w: 19,
    h: 19,
});

const sizes = {
    sm: {
        container: sizeSm,
        excessLabel: sizeSm,
    },
    md: {
        container: sizeMd,
        excessLabel: sizeMd,
    },
    lg: {
        container: sizeLg,
        excessLabel: sizeLg,
    },
};

export const Avatar = defineMultiStyleConfig({
    baseStyle,
    sizes,
    defaultProps: {
        size: 'md',
    },
});
