import { popoverAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, cssVar } from '@chakra-ui/react';
const $arrowBg = cssVar('popper-arrow-bg');

const { definePartsStyle } = createMultiStyleConfigHelpers(popoverAnatomy.keys);

export const specialHintVariant = definePartsStyle({
    body: {
        p: 0,
    },

    closeButton: {
        position: 'static',
    },

    header: {
        fontWeight: 'bold',
        fontSize: 'lg',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: 0,
    },

    content: {
        borderRadius: 'md',
        fontSize: 'sm',
        bg: 'general.heading',
        color: 'white',
        p: 6,
        [$arrowBg.variable]: 'colors.general.heading',
        display: 'flex',
        flexDir: 'column',
        gap: 4,
        maxW: '30ch',
    },

    arrow: {
        borderRadius: '3px',
    },

    footer: {
        display: 'flex',
        justifyContent: 'flex-start',
        borderTop: 'none',
        p: 0,
    },
});
