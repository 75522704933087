const baseTextStyle = {
    fontFamily: 'primary',
    fontStyle: 'normal',
};

export const textStyles = {
    body: {
        ...baseTextStyle,
        fontSize: 'md',
        fontWeight: '400',
        lineHeight: '150%',
        letterSpacing: '0.16px',
    },
    h1: {
        ...baseTextStyle,
        fontSize: '4.8rem',
        fontWeight: '500',
        lineHeight: '120%',
        letterSpacing: '-0.48px',
    },
    h2: {
        ...baseTextStyle,
        fontSize: '4rem',
        fontWeight: '400',
        lineHeight: '125%',
        letterSpacing: '-0.8px',
    },
    h3: {
        ...baseTextStyle,
        fontSize: '2.8rem',
        fontWeight: '400',
        lineHeight: '130%',
        letterSpacing: '0.28px',
    },
    h4: {
        ...baseTextStyle,
        fontSize: '2.3rem',
        fontWeight: '500',
        lineHeight: '130%',
        letterSpacing: '-0.23px',
    },
    h5: {
        ...baseTextStyle,
        fontSize: '1.9rem',
        fontWeight: '400',
        lineHeight: '130%',
        letterSpacing: 'normal',
    },
    small: {
        ...baseTextStyle,
        fontSize: '1.3rem',
        fontWeight: '400',
        lineHeight: '140%',
        letterSpacing: '0.26px',
    },
    caption: {
        ...baseTextStyle,
        fontSize: 'xs',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '160%',
        letterSpacing: '0.36px',
    },
    genericLabel: {
        ...baseTextStyle,
        fontSize: 'md',
        fontWeight: '600',
        lineHeight: '120%',
        letterSpacing: '-0.16px',
    },
    error: {
        ...baseTextStyle,
        fontSize: 'sm',
        whiteSpace: 'normal',
        fontWeight: '700',
        opacity: '0.8',
        lineHeight: '1.8rem',
    },
};
