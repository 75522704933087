export const colors = {
    general: {
        background: '#f5f5f7',
        font: '#303458',
        fontGray: '#6E748A',
        gray: '#787b93',
        lightGray: '#9899ac',
        lightenGray: '#a5a8c6',
        invisibleGray: '#dbdbe9',
        blue: '#4955ff',
        deepBlue: '#0741ed',
        lightBlue: '#edeeff',
        mainBlue: '#1953ff',
        darkBlue: '#4a518a',
        lightenDarkBlue: '#3a4372',
        green: '#00d56d',
        dream: '#55dde0',
        skyBlue: '#f7f7ff',
        orange: '#ff8d18',
        backgroundActive: '#dadcfc',
        caption: '#00d56d',
        heading: '#193380',
        subheading: '#405080',
        paragraph: '#6f7380',
        sectionBg: '#f5f9ff',
        sectionBorder: '#e9efff',
        brightGray: '#fcfdff',
        brightSteelGray: '#dee7ff',
        menuGray: '#f2f5fa',
        grayishBlue: '#f3f6ff',
        yellow: '#ffc866',
        mutedBorder: '#c9cce2',
        mutedGray: '#858999',
        linkHover: '#0056b3',
    },

    blue: {
        font: '#fff',
        500: '#4955ff',
        600: '#444fec',
    },

    primary: {
        font: {
            base: '#fff',
            hover: '#fff',
            active: '#fff',
        },
        fontOutline: {
            base: '#4955ff',
            hover: '#fff',
            active: '#fff',
        },
        fontGhost: {
            base: '#4955ff',
            hover: '#fff',
            active: '#fff',
        },
        500: '#4955ff',
        600: '#0047ff',
        700: '#414ce5',
    },

    mainPrimary: {
        font: {
            base: '#fff',
            hover: '#fff',
            active: '#fff',
        },
        fontOutline: {
            base: '#1953ff',
            hover: '#1953ff',
            active: '#1953ff',
        },
        fontGhost: {
            base: '#1953ff',
            hover: '#fff',
            active: '#fff',
        },
        alternate: {
            hover: 'rgba(48, 100, 255, 0.1)',
            active: 'rgba(48, 100, 255, 0.1)',
        },
        500: '#1953ff',
        600: '#3064FF',
        700: '#3064FF',
    },

    secondary: {
        font: {
            base: '#4955ff',
            hover: '#4955ff',
            active: '#4955ff',
        },
        fontOutline: {
            base: '#4955ff',
            hover: '#0047ff',
            active: '#414ce5',
        },
        fontGhost: {
            base: '#4955ff',
            hover: '#0047ff',
            active: '#414ce5',
        },
        alternative: {
            hover: '#e5e5e5',
            active: '#f7f7fe',
        },
        500: '#eceeff',
        600: '#f3f7ff',
        700: '#ebecf9',
    },

    success: {
        font: {
            base: '#fff',
            hover: '#fff',
            active: '#fff',
        },
        fontOutline: {
            base: '#00d56d',
            hover: '#fff',
            active: '#fff',
        },
        fontGhost: {
            base: '#00d56d',
            hover: '#fff',
            active: '#fff',
        },
        500: '#00d56d',
        600: '#00ea78',
        700: '#33c57d',
    },

    successAlternate: {
        font: {
            base: '#00d56d',
            hover: '#00d56d',
            active: '#00d56d',
        },
        fontOutline: {
            base: '#00d56d',
            hover: '#00d56d',
            active: '#00d56d',
        },
        fontGhost: {
            base: '#00d56d',
            hover: '#00d56d',
            active: '#00d56d',
        },
        500: '#e5fbf0',
        600: '#d0f6e5',
        700: '#ddede7',
    },

    danger: {
        font: {
            base: '#ff3f1a',
            hover: '#ff0000',
            active: '#ff0000',
        },
        fontOutline: {
            base: '#ff3f1a',
            hover: '#ff0000',
            active: '#ff0000',
        },
        fontGhost: {
            base: '#ff3f1a',
            hover: '#ff0000',
            active: '#ff0000',
        },
        500: '#f6e2e1',
        600: '#f5d0d2',
        700: '#f5d0d2',
    },

    lightGray: {
        font: {
            base: '#303458',
            hover: '#303458',
            active: '#303458',
        },
        fontOutline: {
            base: '#303458',
            hover: '#303458',
            active: '#303458',
        },
        fontGhost: {
            base: '#303458',
            hover: '#303458',
            active: '#303458',
        },
        500: '#f7f7ff',
        600: '#eceeff',
        700: '#dadcfc',
    },
};
