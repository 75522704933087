import { cssVar, defineStyleConfig } from '@chakra-ui/react';
const $arrowBg = cssVar('popper-arrow-bg');

export const Tooltip = defineStyleConfig({
    baseStyle: {
        borderRadius: 'sm',
        fontSize: 'sm',
        bg: 'general.darkBlue',
        color: 'white',
        [$arrowBg.variable]: 'colors.general.darkBlue',
    },
    sizes: {
        hint: {
            px: 2,
            py: 1,
        },

        tooltip: {
            px: 7,
            py: 6,
        },
    },
    defaultProps: {
        size: 'hint',
    },
});
