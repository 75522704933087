import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { variantFast } from './variants';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys);

export const sizes = {
    sm: {
        fontSize: 'sm',
        px: 3,
        h: 10,
    },
    md: {
        fontSize: 'md',
        px: 5,
        h: 13,
    },
    lg: {
        fontSize: 'xl',
        px: 6,
        h: 16,
    },
};

export const Input = defineMultiStyleConfig({
    sizes: {
        sm: {
            field: {
                ...sizes.sm,
            },
            addon: {
                ...sizes.sm,
            },
        },
        md: {
            field: {
                ...sizes.md,
            },
            addon: {
                ...sizes.md,
            },
        },
        lg: {
            field: {
                ...sizes.lg,
            },
            addon: {
                ...sizes.lg,
            },
        },
    },
    variants: {
        fast: variantFast,
    },
    defaultProps: {
        size: 'md',
        variant: 'fast',
    },
});
