import { listAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
    listAnatomy.keys,
);

const unordered = definePartsStyle({
    item: {
        listStyle: 'none',
        _before: {
            content: '"•"',
            color: 'general.blue',
            display: 'inline-block',
            width: '1em',
            marginLeft: '-1em',
        },
    },
});

const ordered = definePartsStyle({});

export const List = defineMultiStyleConfig({
    variants: {
        unordered,
        ordered,
    },
});
