import { defineStyle } from '@chakra-ui/react';

export const variantSolid = defineStyle((props) => {
    const { colorScheme } = props;

    const baseStyle = {
        color: `${colorScheme}.font.base`,
        bg: `${colorScheme}.500`,
    };

    return {
        ...baseStyle,
        _hover: {
            color: `${colorScheme}.font.hover`,
            bg: `${colorScheme}.600`,
            _disabled: {
                ...baseStyle,
            },
        },
        _active: {
            color: `${colorScheme}.font.active`,
            bg: `${colorScheme}.700`,
            _disabled: {
                ...baseStyle,
            },
        },
        _focus: {
            color: `${colorScheme}.font.active`,
            bg: `${colorScheme}.700`,
            _disabled: {
                ...baseStyle,
            },
        },
    };
});
