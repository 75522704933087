import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { ssrContext } from '../../contexts/GlobalContext';

export { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

/**
 * When using multiple forms that use GoogleReCaptchaProvider,
 * it tries to create multiple instances of Google ReCaptcha, but fails.
 * So we try to use window.grecaptcha when useGoogleRecaptcha is not available.
 */
export function useReCaptcha() {
    const { executeRecaptcha: executeGoogleRecaptcha, container } = useGoogleReCaptcha();

    async function executeRecaptcha(action?: string) {
        if (executeGoogleRecaptcha === undefined) {
            if (window.grecaptcha === undefined) {
                throw new Error('ReCaptcha is not loaded');
            }

            const grecaptcha = window.grecaptcha;
            return grecaptcha.execute(ssrContext.recaptchaKey, { action });
        }

        return await executeGoogleRecaptcha(action);
    }

    return { container, executeRecaptcha };
}
