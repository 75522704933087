import { formAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle } = createMultiStyleConfigHelpers(formAnatomy.keys);

export const fastVariant = definePartsStyle({
    container: {
        boxShadow:
            'inset -10px -10px 10px rgba(255, 255, 255, 0.3), inset 10px 10px 10px rgba(103, 114, 229, 0.04)',
        borderRadius: '1rem',
        color: 'general.font',
        minH: '6.4rem',
        fontSize: 'xl',
        lineHeight: 6.5,
        bg: '#fff',
        zIndex: 0,

        label: {
            fontSize: '1rem',
            zIndex: 1,
            position: 'absolute',
            top: 0,
            left: 0,
            userSelect: 'none',
            mb: 0,
            ml: 5,
            mt: '2rem',
            lineHeight: '1.2rem',
        },

        input: {
            h: '100% !important',
        },
    },
});
