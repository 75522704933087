import { tableAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
    tableAnatomy.keys,
);

const simpleVariant = definePartsStyle({
    table: {
        color: 'general.darkBlue',
    },

    thead: {
        bg: 'general.lightBlue',
    },

    th: {
        _first: {
            borderTopLeftRadius: 'base',
        },

        _last: {
            borderTopRightRadius: 'base',
        },

        fontWeight: 'bold',
        textAlign: 'center',
        textTransform: 'none',
    },

    td: {
        textAlign: 'center',
        borderStyle: 'solid',
        borderWidth: '2px',
        borderColor: 'general.lightBlue',
    },
});

const stripedVariant = definePartsStyle({
    ...simpleVariant,
});

const sizeSm = definePartsStyle({
    td: {
        fontSize: 'sm',
    },
    th: {
        fontSize: 'sm',
    },
});

const sizeMd = definePartsStyle({
    td: {
        fontSize: 'md',
    },
    th: {
        fontSize: 'md',
    },
});

const sizeLg = definePartsStyle({
    td: {
        fontSize: 'lg',
    },
    th: {
        fontSize: 'lg',
    },
});

export const Table = defineMultiStyleConfig({
    variants: {
        simple: simpleVariant,
        striped: stripedVariant,
    },
    sizes: {
        sm: sizeSm,
        md: sizeMd,
        lg: sizeLg,
    },
    defaultProps: {
        variant: 'simple',
    },
});
