import { formAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { baseVariant, fastVariant, futureVariant } from './variants';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(formAnatomy.keys);

export const Form = defineMultiStyleConfig({
    variants: {
        base: baseVariant,
        fast: fastVariant,
        future: futureVariant,
    },

    defaultProps: {
        variant: 'base',
    },
});
