import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
    checkboxAnatomy.keys,
);

const baseStyle = definePartsStyle((props) => {
    const { colorScheme } = props;

    return {
        container: {
            alignItems: 'center',
        },
        label: {
            _invalid: {
                color: 'red.500',
            },
            ml: 3,
        },
        control: {
            p: 2,
            borderRadius: 'xs',
            borderWidth: '1px',
            borderColor: 'general.invisibleGray',
            _active: {
                transform: 'scale(1.1)',
                boxShadow: '2px 4px 8px rgba(119, 126, 226, 0.4), 0 0 4px rgba(119, 126, 226, 0.4)',
                borderColor: 'transparent',
            },
            _disabled: {
                transform: 'none !important',
                boxShadow: 'none !important',
            },
        },
        icon: {
            color: `${colorScheme}.font.base`,
        },
    };
});

const futureStyle = definePartsStyle((props) => {
    return {
        container: {
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
        },
        label: {
            textAlign: 'left',
            color: 'general.mutedGray',
            fontSize: 'sml',
            fontWeight: 'normal',
            letterSpacing: 'spacious',
            lineHeight: 'fine',
            _invalid: {
                color: 'red.500',
            },
        },
    };
});

export const Checkbox = defineMultiStyleConfig({
    baseStyle,
    variants: {
        base: baseStyle,
        future: futureStyle,
    },
    defaultProps: {
        colorScheme: 'primary',
    },
});
