import { defineStyleConfig } from '@chakra-ui/react';
import type { StyleFunctionProps, SystemStyleFunction } from '@chakra-ui/theme-tools';

import { variantSolid, variantOutline, variantGhost, variantFast } from './variants';
import { textStyles } from '../../textStyles';

const toResponsive = (props: StyleFunctionProps, variant: SystemStyleFunction) => {
    return {
        w: {
            base: '100%',
            sm: '100%',
            md: 'auto',
        },
        ...variant(props),
    };
};

const { fontSize: _, ...genericLabel } = textStyles.genericLabel;

export const Button = defineStyleConfig({
    baseStyle: {
        ...genericLabel,
        textDecor: 'none',
        _hover: {
            textDecor: 'none',
        },
    },
    sizes: {
        sm: {
            fontSize: 'sm',
            borderRadius: 'sm',
            px: 6,
            h: 10,
            minW: 10,
        },
        md: {
            fontSize: 'md',
            borderRadius: 'base',
            px: 8,
            h: 13.5,
            minW: 13.5,
        },
        lg: {
            fontSize: 'lg',
            borderRadius: 'md',
            px: 9,
            h: 14,
            minW: 14,
        },
    },
    variants: {
        solid: variantSolid,
        'solid-responsive': (props) => toResponsive(props, variantSolid),
        outline: variantOutline,
        'outline-responsive': (props) => toResponsive(props, variantOutline),
        ghost: variantGhost,
        'ghost-responsive': (props) => toResponsive(props, variantGhost),
        fast: variantFast,
        'fast-responsive': (props) => toResponsive(props, variantFast),
    },
    defaultProps: {
        size: 'md',
        variant: 'solid',
        colorScheme: 'primary',
    },
});
