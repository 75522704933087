import { createContext, useContext, type MutableRefObject, type ComponentProps } from 'react';

export type AppContextType = {
    containerRef: MutableRefObject<HTMLDivElement | null>;
} & ComponentProps<any>;

const AppContext: React.Context<AppContextType> = createContext(null as any);

export function useAppContext() {
    return useContext(AppContext);
}

export function AppContextProvider({ children, containerRef }: AppContextType) {
    return <AppContext.Provider value={{ containerRef }}>{children}</AppContext.Provider>;
}
