import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

import { colors } from '../../foundations/colors';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle((props) => {
    const { colorScheme: c } = props;

    const currentColor = Object.keys(colors).find((i) => i === c);

    return {
        header: {
            color: currentColor !== undefined ? `${c}.font.base` : 'general.darkBlue',
        },

        dialog: {
            borderRadius: 'md',
        },
    };
});

const sizes = {
    xl: definePartsStyle({
        header: {
            fontSize: '3xl',
            px: 8,
            pt: 8,
        },
        dialog: {
            fontSize: 'lg',
        },
        body: {
            px: 8,
            pb: 8,
        },
    }),
};

export const Modal = defineMultiStyleConfig({
    baseStyle,
    sizes,
    variants: {
        changelog: {
            dialogContainer: {
                justifyContent: { base: 'center', lg: 'flex-end' },
                paddingRight: { base: 0, lg: 8 },
            },
        },
    },
    defaultProps: {
        size: 'xl',
    },
});
