import { radius as radii } from './radius';
import { colors } from './colors';
import { sizes } from './sizes';
import { spacing as space } from './spacing';
import { typography } from './typography';

export const foundations = {
    colors,
    ...typography,
    sizes,
    space,
    radii,
};
