import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const getBaseStyle = defineStyle((props) => {
    const { colorScheme: c } = props;

    return {
        fontWeight: 'normal',
        fontSize: 'sm',
        borderRadius: 'sm',
        color: `${c}.font.base`,
        bg: `${c}.500`,
        py: 1,
        px: 2,
    };
});

export const Badge = defineStyleConfig({
    variants: {
        default: getBaseStyle,
    },
    defaultProps: {
        colorScheme: 'primary',
        variant: 'default',
    },
});
