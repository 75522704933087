import { alertAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, cssVar } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
    alertAnatomy.keys,
);

const baseStyle = definePartsStyle(() => {
    const $closeButton = cssVar('close-button-size');

    return {
        title: {
            fontSize: 'md',
            lineHeight: 6.5,
        },

        container: {
            fontSize: 'sm',
            button: {
                [$closeButton.variable]: '2.4rem',
            },
        },
    };
});

export const Alert = defineMultiStyleConfig({ baseStyle });
