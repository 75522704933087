import { radioAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
    radioAnatomy.keys,
);

const baseStyle = definePartsStyle(() => {
    const dot = {
        content: '""',
        display: 'block',
        borderRadius: '100%',
        w: 1,
        h: 1,
        minW: 1,
        minH: 1,
        bg: 'white',
        zIndex: 10000,
    };

    return {
        label: {
            ml: '1.5rem',
        },
        control: {
            borderWidth: '1px',
            borderColor: 'general.invisibleGray',
            p: 2,

            _checked: {
                _after: dot,
            },

            _hover: {
                _after: dot,
            },

            _active: {
                transform: 'scale(1.1)',
                boxShadow: '2px 4px 8px rgba(119, 126, 226, 0.4), 0 0 4px rgba(119, 126, 226, 0.4)',
            },

            _disabled: {
                transform: 'none !important',
                boxShadow: 'none !important',
            },
        },
    };
});

export const Radio = defineMultiStyleConfig({
    baseStyle,
    defaultProps: {
        colorScheme: 'primary',
    },
});
