import { formAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle } = createMultiStyleConfigHelpers(formAnatomy.keys);

function color(colorScheme?: string) {
    switch (colorScheme) {
        case 'base':
            return {
                borderColor: 'general.mutedBorder',
                background: 'white',
                boxShadow:
                    '10px 10px 10px 0px rgba(103, 114, 229, 0.04) inset, -10px -10px 10px 0px rgba(255, 255, 255, 0.30) inset',
            };
        default:
            return {
                borderColor: 'general.mutedBorder',
                background: 'general.sectionBg',
                boxShadow:
                    '10px 10px 10px 0px rgba(103, 114, 229, 0.04) inset, -10px -10px 10px 0px rgba(255, 255, 255, 0.30) inset',
            };
    }
}

export const futureVariant = definePartsStyle((props) => {
    const { colorScheme } = props;

    return {
        container: {
            control: {
                textAlign: 'left',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
            },

            label: {
                color: 'general.subheading',
                fontSize: 'lgr',
                fontWeight: 'normal',
                mb: '6',
            },

            'input, textarea': {
                display: 'flex',
                width: 'full',
                padding: '5 4',
                flexShrink: 0,
                borderRadius: 'radii.sml',
                border: '1px solid',
                ...color(colorScheme),
            },

            textarea: {
                padding: '5',
            },
        },
    };
});
