import { accordionAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle } = createMultiStyleConfigHelpers(accordionAnatomy.keys);

export const baseVariant = definePartsStyle({
    container: {
        border: 'none',
    },
    button: {
        color: 'general.gray',
        fontSize: '1.5rem',
        _hover: {
            bg: 'transparent',
        },
        borderBottom: 'solid 1px #C9CCE2',
        px: 0,
    },
    icon: {
        mr: 1,
        color: 'general.blue',
    },
    panel: {
        pt: '2.2rem',
        px: 0,
    },
});
