import { extendTheme } from '@chakra-ui/react';

import { config } from './config';
import { styles } from './styles';
import { textStyles } from './textStyles';
import { layerStyles } from './layerStyles';
import { foundations } from './foundations';
import { components } from './components';

export const theme = extendTheme({
    config,
    styles,
    textStyles,
    layerStyles,
    ...foundations,
    components,
});
