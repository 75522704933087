import { popoverAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { hintVariant, dialogVariant, specialHintVariant } from './variants';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(popoverAnatomy.keys);

export const Popover = defineMultiStyleConfig({
    baseStyle: {
        popper: {
            zIndex: 7000,
        },

        header: {
            borderBottom: 'none',
        },

        footer: {
            borderTop: 'none',
        },

        content: {
            border: 'none',
        },
    },
    variants: {
        hint: hintVariant,
        dialog: dialogVariant,
        specialHint: specialHintVariant,
    },
    defaultProps: {
        variant: 'hint',
    },
});
