import { defineStyle } from '@chakra-ui/react';

export const variantOutline = defineStyle((props) => {
    const { colorScheme } = props;

    const variantStyle = {
        bg: 'transparent',
        color: `${colorScheme}.fontOutline.base`,
        borderColor: `${colorScheme}.fontOutline.base`,
    };

    let hover = {
        borderColor: `${colorScheme}.600`,
        bg: `${colorScheme}.600`,
    };

    let active = {
        borderColor: `${colorScheme}.700`,
        bg: `${colorScheme}.700`,
    };

    if (props.theme.colors[colorScheme].alternate !== undefined) {
        hover = {
            borderColor: `${colorScheme}.fontOutline.hover`,
            bg: `${colorScheme}.alternate.hover`,
        };

        active = {
            borderColor: `${colorScheme}.fontOutline.active`,
            bg: `${colorScheme}.alternate.active`,
        };
    }

    return {
        ...variantStyle,
        borderWidth: '1px',
        borderStyle: 'solid',
        _hover: {
            color: `${colorScheme}.fontOutline.hover`,
            ...hover,
            _disabled: {
                ...variantStyle,
            },
        },
        _active: {
            color: `${colorScheme}.fontOutline.active`,
            ...active,
            _disabled: {
                ...variantStyle,
            },
        },
        _focus: {
            color: `${colorScheme}.fontOutline.active`,
            ...active,
            _disabled: {
                ...variantStyle,
            },
        },
    };
});
