import { type ComponentProps, createContext, useContext } from 'react';

export const ssrContext = window?.__ssr_context;

const GlobalContext: React.Context<typeof window.__ssr_context> = createContext(ssrContext);
export type GlobalContextProps = Omit<ComponentProps<typeof GlobalContext.Provider>, 'value'>;

export function GlobalContextProvider(props: GlobalContextProps) {
    return <GlobalContext.Provider value={ssrContext}>{props.children}</GlobalContext.Provider>;
}

export function useGlobalContext() {
    return useContext(GlobalContext);
}
