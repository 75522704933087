import { switchAnatomy } from '@chakra-ui/anatomy';
import { cssVar, createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle } = createMultiStyleConfigHelpers(switchAnatomy.keys);

export const insideVariant = definePartsStyle((props) => {
    const { colorScheme: c } = props;

    return {
        container: {
            display: 'flex',
            alignItems: 'center',
            gap: 2,
        },
        thumb: {
            bg: `general.lightenGray`,
            _checked: {
                bg: `${c}.500`,
            },
        },
        track: {
            bg: 'general.invisibleGray',
            _checked: {
                bg: 'general.invisibleGray',
            },
        },
    };
});

export const insideSizer = ({ trackWidth }: { trackWidth: number | string }) => {
    const $switchTrackWidth = cssVar('switch-track-width');

    return {
        container: {
            [$switchTrackWidth.variable]: trackWidth,
        },
    };
};
