import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, StyleFunctionProps } from '@chakra-ui/react';

import { outsideVariant, insideVariant, outsideSizer, insideSizer } from './variants';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
    switchAnatomy.keys,
);

const variantSizer = (variants: Record<string, any>) => {
    return definePartsStyle((props) => {
        return variants[props.variant] !== undefined ? variants[props.variant](props) : {};
    });
};

export const Switch = defineMultiStyleConfig({
    variants: {
        outside: outsideVariant,
        inside: insideVariant,
    },

    sizes: {
        lg: variantSizer({
            outside: (props: StyleFunctionProps) => outsideSizer({ props, thumbSize: 8 }),
            inside: () => insideSizer({ trackWidth: '4.5rem' }),
        }),
        md: variantSizer({
            outside: (props: StyleFunctionProps) => outsideSizer({ props, thumbSize: 6 }),
            inside: () => insideSizer({ trackWidth: '3rem' }),
        }),
        sm: variantSizer({
            outside: (props: StyleFunctionProps) => outsideSizer({ props, thumbSize: 4 }),
            inside: () => insideSizer({ trackWidth: '2.5rem' }),
        }),
    },

    defaultProps: {
        colorScheme: 'primary',
        variant: 'outside',
        size: 'md',
    },
});
