export const layerStyles = {
    shadowedField: {
        borderRadius: '1rem',
        border: '1px solid',
        borderColor: 'general.sectionBorder',
        background: '#fff',
        boxShadow:
            '10px 10px 10px 0px rgba(103, 114, 229, 0.04) inset, -10px -10px 10px 0px rgba(255, 255, 255, 0.30) inset !important',
        transitionProperty: 'common',
        transitionDuration: 'fast',
        _focusWithin: {
            borderColor: 'general.mainBlue',
        },
    },
};
