import { formAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle } = createMultiStyleConfigHelpers(formAnatomy.keys);

export const baseVariant = definePartsStyle({
    container: {
        label: {
            fontSize: 'sm',
            fontWeight: 'bold',
        },
    },
});
