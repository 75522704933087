import { defineStyle } from '@chakra-ui/react';

export const variantGhost = defineStyle((props) => {
    const { colorScheme } = props;

    const variantStyle = {
        bg: 'transparent',
        color: `${colorScheme}.fontGhost.base`,
        border: 'none',
    };

    let hover = {
        bg: `${colorScheme}.600`,
    };

    let active = {
        bg: `${colorScheme}.700`,
    };

    if (props.theme.colors[colorScheme].alternate !== undefined) {
        hover = {
            bg: `${colorScheme}.alternate.hover`,
        };

        active = {
            bg: `${colorScheme}.alternate.active`,
        };
    }

    return {
        ...variantStyle,
        _hover: {
            color: `${colorScheme}.fontGhost.hover`,
            ...hover,
            _disabled: {
                ...variantStyle,
            },
        },
        _active: {
            color: `${colorScheme}.fontGhost.active`,
            ...active,
            _disabled: {
                ...variantStyle,
            },
        },
        _focus: {
            color: `${colorScheme}.fontGhost.active`,
            ...active,
            _disabled: {
                ...variantStyle,
            },
        },
    };
});
