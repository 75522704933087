import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
    menuAnatomy.keys,
);

const baseStyle = definePartsStyle({
    item: {
        fontSize: 'md',
        color: 'general.blue',
        py: 2.5,
        px: 5,
        _hover: {
            bg: 'rgba(73, 85, 255, 0.1)',
            color: 'general.blue',
        },
    },
    list: {
        border: 'none',
        boxShadow: '0 5px 25px 0 rgba(73, 85, 255, 0.13)',
    },
    groupTitle: {
        color: 'general.gray',
    },
    divider: {
        borderColor: 'general.invisibleGray',
    },
    command: {
        fontSize: 'xs',
    },
});

export const Menu = defineMultiStyleConfig({
    baseStyle,
});
