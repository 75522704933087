import { defineStyle } from '@chakra-ui/react';

export const variantFast = defineStyle(() => {
    const baseStyles = {
        color: 'blue.font',
        bg: 'blue.500',
    };

    const hoverStyles = {
        color: 'blue.font',
        bg: 'blue.600',
    };

    return {
        ...baseStyles,
        boxShadow: 'inset 0px 20px 30px rgba(204, 249, 255, 0.2) !important',
        fontSize: 'xl',
        fontWeight: 'bold',
        py: '1.6rem',
        px: '4rem',
        h: 'auto',
        lineHeight: '2.6rem',
        _hover: {
            ...hoverStyles,
            _disabled: {
                ...baseStyles,
            },
        },
        _active: {
            ...hoverStyles,
            _disabled: {
                ...baseStyles,
            },
        },
        _focus: {
            ...hoverStyles,
            _disabled: {
                ...baseStyles,
            },
        },
        _disabled: {
            opacity: 0.6,
        },
    };
});
