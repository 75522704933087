import { Button } from './Button';
import { Link } from './Link';
import { Form } from './Form';
import { Input } from './Input';
import { Textarea } from './Textarea';
import { Select } from './Select';
import { Heading } from './Heading';
import { Modal } from './Modal';
import { Tooltip } from './Tooltip';
import { Badge } from './Badge';
import { Breadcrumb } from './Breadcrumb';
import { Switch } from './Switch';
import { Avatar } from './Avatar';
import { Checkbox } from './Checkbox';
import { Radio } from './Radio';
import { Table } from './Table';
import { Tabs } from './Tabs';
import { Popover } from './Popover';
import { List } from './List';
import { Menu } from './Menu';
import { Accordion } from './Accordion';
import { Alert } from './Alert';

export const components = {
    Avatar,
    Button,
    Link,
    Form,
    Input,
    Textarea,
    Select,
    Heading,
    Modal,
    Tooltip,
    Badge,
    Breadcrumb,
    Switch,
    Checkbox,
    Radio,
    Table,
    Tabs,
    Popover,
    List,
    Menu,
    Accordion,
    Alert,
};
