import { breadcrumbAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
    link: {
        fontSize: 'md',
        textDecor: 'underline',
        color: 'general.darkBlue',

        _hover: {
            color: 'general.blue',
        },
    },

    separator: {
        textDecor: 'none',
        mx: 3,
    },
});

export const Breadcrumb = defineMultiStyleConfig({
    baseStyle,
});

export const BreadcrumbSeparator = '›';
