export const styles = {
    global: {
        html: {
            fontSize: '10px',
        },
        body: {
            fontSize: '1.6rem',
            bgGradient: 'linear(#ffffff 120px, #f5f5f7 120px)',
            backgroundRepeat: 'no-repeat',
            color: 'general.font',
        },
        '#chakra-toast-manager-top': { zIndex: '1000001 !important' },
        '#chakra-toast-manager-top-left': { zIndex: '1000001 !important' },
        '#chakra-toast-manager-top-right': { zIndex: '1000001 !important' },
        '#chakra-toast-manager-bottom': { zIndex: '1000001 !important' },
        '#chakra-toast-manager-bottom-left': { zIndex: '1000001 !important' },
        '#chakra-toast-manager-bottom-right': { zIndex: '1000001 !important' },
    },
};
