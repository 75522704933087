const fontPrimary = `'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`;

export const typography = {
    fonts: {
        heading: fontPrimary,
        body: fontPrimary,
        primary: fontPrimary,
    },

    fontSizes: {
        '3xs': '0.8rem',
        '2xs': '1rem',
        xs: '1.2rem',
        sml: '1.3rem',
        sm: '1.4rem',
        md: '1.6rem',
        lg: '1.8rem',
        lgr: '1.9rem',
        xl: '2rem',
        '2xl': '2.4rem',
        '3xl': '3rem',
        '4xl': '3.6rem',
        '5xl': '4rem',
        '6xl': '4.8rem',
        '7xl': '5.6rem',
        '8xl': '8rem',
        '9xl': '9.6rem',
    },

    letterSpacings: {
        tighter: '-0.08rem',
        tight: '-0.04rem',
        normal: '0',
        spacious: '0.026rem',
        wide: '0.04rem',
        wider: '0.08rem',
        widest: '0.16rem',
    },

    lineHeights: {
        normal: 'normal',
        none: 1,
        shorter: 1.25,
        short: 1.375,
        fine: 1.4,
        base: 1.5,
        tall: 1.625,
        taller: '2',
        '3': '1.2rem',
        '4': '1.6rem',
        '5': '1.25rem',
        '6': '2rem',
        '6.5': '2.4rem',
        '7': '2.8rem',
        '8': '3.2rem',
        '9': '3.6rem',
        '10': '4rem',
    },
};
