export const radius = {
    none: '0',
    xs: '0.4rem',
    sml: '0.6rem',
    sm: '0.8rem',
    base: '1.2rem',
    md: '1.4rem',
    lg: '1.6rem',
    xl: '2rem',
    '2xl': '2.4rem',
    '3xl': '3.2rem',
    full: '9999px',
};
